/* @import "rsuite/dist/rsuite.css"; */

/*custom*/
/* .validation-error {color: red;text-transform: uppercase;letter-spacing: .3px;display: block;font-size: 12px;}
.register-page .register-left img{height:100%;width:100%;object-fit:cover}
.form-check-input:checked{background-color:#237234!important;border-color:#237234!important;box-shadow:0 0!important}
.sap-btn-dark{background-color:#237234!important;color:#fff!important}
.sap-btn-dark:hover{background-color:#195425!important}
.sap-btn-light{background-color:#c94a1e!important;color:#fff!important}
.sap-btn-light:hover{background-color:#9b3b1a!important}
.navbar.navbar-dark{background-color:#237234!important}
.sb-sidenav-dark{background-color:#103c19!important}
.sb-sidenav .sb-sidenav-menu .nav .nav-link{color:#fff;transition:all .3s ease-in-out}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon{color:#fff!important;transition:all .3s ease-in-out}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading{color:rgb(255 255 255 / 40%)}
.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover{color:#d1a030}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon{color:#d1a030!important}
.sb-topnav .navbar-brand{color:#d1a030;font-weight:500}
.progress-bar{background-color:#237234}
.prf-img-set img{object-fit:cover}
.edit-prf{border-left:1px solid #f2f2f2}
.sb-sidenav-footer{background-color:#237234!important}
.portal-overlay{position:fixed;top:0;left:0;bottom:0;right:0;z-index:1000000;width:100%;height:100%;background:rgba(0,0,0,0.5)}
.portal-overlay .confirm-dialog{z-index:1000005;padding:16px;background-color:white;width:400px;position:absolute;top:200px;left:50%;transform:translate(-50%,-50%);border:1px solid rgba(0,0,0,0.2);border-radius:5px}
.portal-overlay .confirm-dialog__footer{display:flex;justify-content:flex-end;margin-top:20px} */


/* css start 19th-dec */



/* .sap-backdrop{background-color:#fff;box-shadow:0 0 10px 0 rgb(0 0 0 / 15%);padding: 20px 20px 40px!important;border-radius:5px;margin:0 auto 20px;min-height:650px}
#layoutSidenav_content{padding:25px 23px}
footer{background-color:#fff!important;box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);    border-radius: 5px;}
.breadcrumb li,.breadcrumb a{color:#a4a4a4 !important}
.breadcrumb-item.active{color:#237234 !important}
.sap-btn-light svg path,.sap-btn-dark svg path{stroke: #fff;}
.sap-btn-light:not(.sap-btn-light *),.sap-btn-dark:not(.sap-btn-dark *){min-height:40px;padding: 11px 21px;border-radius:5px;}
.dual-btn button{margin:0 12px 0 0}
.sap-lg-heading{font-size:24px;font-weight:400;padding:20px 0 10px}
.sap-md-heading{font-size:20px;font-weight:400}
.sap-sm-heading{font-size:18px;font-weight:400}
.subtitle{color:#a4a4a4;font-size:14px}
.assesment-inner-list, .monitor-eva-inner{background-color:#f2f2f2;margin:0 auto 15px;padding:15px;border-radius:5px;text-decoration:none;color:#000!important;transition:all .3s ease-in-out;display:flex;align-items:center}
.assesment-inner-list .sap-sm-heading a{color:inherit;text-decoration:none}
.assesment-inner-list:hover{background-color:#e9e9e9}
#lesson-module .ail-graph-perc .progress{width:50px;height:50px}
#lesson-module .ail-graph-perc .progress .progress-bar{border-width:3px;border-color:#237234}
#lesson-module .ail-graph-perc .progress .progress-value{font-size:14px;font-weight:400;display:flex;width:100%;position:relative;top:-4px}
#lesson-module .ail-graph-perc .progress:after{border-width:3px;border-color:#d9d9d9}
.no-training-mod{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:20px 0 60px}
.no-training-mod svg{height:250px;width:auto}
.no-training-mod .ntm-img{padding:0 0 15px}
.training-mod-list{width:100%;max-width:820px;margin-top:15px}
.training-mod-list .tml-img img{width:100%;height:100%;object-fit:cover}
.training-mod-list .tml-img{height:250px;width:350px;padding:0 0 12px}
.training-mod-list .tml-location{padding:4px 0 0;display:flex;align-items:center;color:#a4a4a4}
.training-mod-list .tml-location svg{padding:0 5px 0 0}
.training-mod-list .tml-location span{padding:1px 0 0}
.monitor-eva-inner {flex-direction: column;align-items: flex-end;}
.monitor-eva-inner .text-para {text-align: left;}
.monitor-eva-head {display: flex;flex-direction: column;align-items: center;justify-content: center;margin: 15px auto 0;}
.monitor-eva-inner .mei-date {color: #237234;font-weight: 500;}
.monitor-eva-head .sap-btn-dark {margin: 15px auto 0;}
.sap-heading-head {display: flex;align-items: center;justify-content: space-between;}
.profile-tab p,.profile-head h6{color:#237234}
.profile-img{min-width: 50px;height: 50px;width: 50px;margin: 0 12px 0 0;}
.profile-img img{object-fit:cover;border-radius:50%;width:100%;height:100%}
.profile-con {display: flex;}
.profile-progress {background-color: #f2f2f2;flex-direction: column;display: inline-flex;padding: 20px;}
.profile-progress .sap-lg-heading {padding: 0;}
.sap-btn-dark svg, .sap-btn-light svg {margin: 0 5px 0 0;}   
.profile-setting-head .profile-progress{margin:20px 0;width:100%;max-width:460px;border-radius:5px}
.profile-setting-head .profile-progress .progress-inner{padding:10px 0 5px}
.profile-setting-head .profile-progress .progress-inner .progress{height:7px;border-radius:10px}
.profile-setting-head .profile-progress .progress-inner .progress .progress-bar{border-radius:10px;background-color:#237234}
.sap-tabination .nav-tabs .nav-link{color:#000}
.sap-tabination .nav-tabs .nav-link.active{color:#c94a1e}
.sap-tabination .tab-pane {padding: 20px 15px;}
.sap-tabination #pills-profile .col-md-6:nth-child(odd) {padding-right: 5%;}
.sap-tabination #pills-profile .col-md-6:nth-child(even) {padding-left: 5%;}
.form-group {margin: 0 0 12px;}
form .form-group label.form-label {color: #9c9c9c !important;text-transform: uppercase !important;letter-spacing: .5px;font-weight: 500 !important; font-size: 14px !important;}
.form-control {min-height: 45px;}
.form-control[type="file"] {padding: 13px 0px 0px 21px !important;}
.dual-btn-switch .sap-btn-dark {background-color: #cfcfcf !important;}
.dual-btn.dual-btn-switch * {transition: all .3s ease-in-out;}
footer div a {color: #237234 !important;margin: 0 10px 0;text-decoration: none;}
.chat-login-check .form-check {display: flex;padding: 0;margin: 0;width: 100%;align-items: baseline;}
.chat-login-check .form-check input {width: auto;margin: 0 10px 0 0;}
.form-group.chat-login-check label.form-label {  margin-bottom: 0;}
.styles_chatLogin__rshPr .sap-lg-heading {padding-top: 0;}
.chat-panel {min-height: auto;padding: 0 !important;}
.user-chat-img {height: 40px;width: 40px;overflow: hidden;border-radius: 50%;margin: 0 8px 0 0;}
.user-chat-img img {height: 100%;width: 100%;object-fit: cover;}
.user-chat-profile-inner.my-active {background-color: rgb(35 114 52 / 15%);padding: 10px 7px;border-radius: 5px;}
.user-chat-profile-inner .user-status {font-size: 10px;text-transform: uppercase;font-weight: 600;}
.user-chat-profile-inner{display: flex;align-items: center;margin: 0 0 12px;}

.user-status{font-size:10px;text-transform:uppercase;font-weight:600}
.user-chat-profile-inner{margin:0 0 10px}
.style-chat-bot div{flex-direction:column;align-content:center;justify-content:center!important;text-align:center;margin:0 0 6px}
.style-chat-bot p{color:#237234;background-color:#b2dcbb;padding:10px;border-radius:5px;text-align:center;line-height:normal;font-size:12px}
.style-chat-bot div span{color:#237234}
.style-chat-bot{display:flex;flex-direction:column;align-items:center}
.style-chat-right{background-color:#237234;border-radius:5px;position:relative;padding:10px;width:auto;display:table;margin:0 0 12px auto}
.style-chat-left{background-color:#d4d4d4;border-radius:5px;position:relative;padding:10px;width:auto;display:table;margin: 0 auto 12px 10px;}
.style-chat-left:before{content:"";border-top:7px solid transparent;border-bottom:7px solid transparent;border-right:10px solid #d4d4d4;display:block;position:absolute;width:5px;height:5px;left:-9px;top:10px;border-radius:2px}
.style-chat-right:before{content:"";border-top:7px solid transparent;border-bottom:7px solid transparent;border-left:10px solid #237234;display:block;position:absolute;width:5px;height:5px;right:-9px;top:10px;border-radius:2px}
.style-chat-right p{color:#fff}
.style-chat-right span{color:#fff;margin:0 0 5px}
.style-chat-left span{color:#6a6a6a;margin:0 0 5px}
.style-chat-left span:first-child{padding:0 20px 0 0}
.style-chat-right span:first-child{padding:0 20px 0 0}
.style-chat-left p, .style-chat-right p, .style-chat-bot p {margin: 0;}

#match-making .custom-control-label {height: 220px;width: 100%;position: relative;background-color: #237234;cursor: pointer;border-radius: 10px;overflow: hidden;}
#match-making .custom-control-label img {height: 100%;width: 100%;object-fit: cover;position:relative;}
#match-making .image-checkbox input[type="checkbox"]:checked + .custom-control-label img {opacity: 0.5;}
#match-making .image-checkbox {display: flex;flex-direction: column;}
#match-making .image-checkbox .text-center {padding: 10px 0 20px;}
#match-making .image-checkbox input {display: none;}

.dataTables_wrapper .btn{background-color: #dedddd;border-radius: 5px !important;padding: 6px 12px;
height: 40px;    display: flex;align-items: center;justify-content: center;}
.dataTables_wrapper .btn:hover {background-color: #c2c2c2;}
.dataTables_wrapper .btn .fa-pen-to-square {color: #424242;}
.dataTables_wrapper .btn .fa-trash {color: #ee5454;}
.dataTables_wrapper .btn .fa-eye {color: #237234;}
.dataTables_wrapper img {  object-fit: cover;}
.active-row {background-color: rgb(35 114 52 / 15%) !important;}
.training-mod-list {padding-top: 25px;}
.tml-inner {margin: 0 0 30px;}
.no-training-mod .sap-btn-dark {margin: 12px auto 0;}
.monitor-eva-head form, .monitor-eva-head form .form-group {width: 100%;} */



/* .dash-list-inner{background-color:#f2f2f2;margin:0 auto 7px;padding:10px;border-radius:5px;text-decoration:none;color:#000!important;transition:all .3s ease-in-out;display:flex;justify-content:space-between;align-items:flex-start}
.dash-list-inner .sap-sm-heading{font-size:14px}
.dash-list-inner .subtitle{font-size:12px}
.dash-right-inner{margin:0 0 20px}
.dash-list-head{margin:7px 0 0}
.dash-right{border:2px solid #f2f2f2;border-radius:5px;padding:10px}
.dash-list-inner:last-child,.dash-right-inner:last-child{margin-bottom:0}
.dash-right a {color: #237234;text-decoration: none;}

.dli-left-inner-img{height:40px;width:40px;overflow:hidden;border-radius:50px;min-width:40px}
.dli-left-inner-img img{height:100%;width:100%;object-fit:cover}
.dli-left-inner{display:flex;align-content:center}
.dli-left-inner .dli-left-inner-con{padding:0 0 0 8px}
.dli-right .time-posted{font-size:10px;color:#a4a4a4;font-weight:500}
.card-regi-right {text-align: center;padding: 60px;}
.card-regi-right .sap-lg-heading, .success-register .sap-lg-heading {padding: 0 0 20px;line-height: normal;}
.text-grey {color: #a4a4a4;}
.text-para, .text-para p {font-size: 14px;line-height: 24px;}
.success-register {padding: 30px;position: relative;display: flex;justify-content: center;height: 100%;}
.text-green {color: #237234;}
.field .pass-eye {position: absolute;bottom: 0px;right: 0;cursor: pointer;display: flex;}
.field {position: relative;}
.field .pass-eye svg path {fill: #a4a4a4;}
.field .pass-eye i {padding: 8px 10px;}
#navbarSupportedContent {display: flex !important;}
#navbarSupportedContent .navbar-nav {display: flex;flex-direction: initial;}
#navbarSupportedContent .navbar-nav .nav-link {padding-right: 0.5rem;padding-left: 0.5rem;}
#layoutSidenav #layoutSidenav_nav.nav-open {transform: translateX(0px);}

.sap-table {width: 100%;}
.sap-table thead th, .sap-table tbody td {padding: 7px 5px;font-size: 14px;}
.sap-table tr:nth-child(even) {background-color: #f9f9f9;}
.sap-table .btn {  background-color: #237234 !important;  line-height: normal;  padding: 8px 10px; color: #fff;  margin: 0px 0px 0 12px;text-decoration: none !important; color: #fff;}
.sap-table .btn i{color: #fff !important;}
.sap-table .btn svg path{fill: #fff !important;}

#layoutSidenav_nav .sb-sidenav-footer {
    background: linear-gradient(to right, red, black);
    color: #fff;
    display: flex;justify-content: space-between;align-items: center;
    padding: 10px 50px;
   
} */

/* .new_btn {padding: 5px 12px;border-radius: 3px;background-color: #000;border:none;}
.new_btn:hover{   background: linear-gradient(to right, red, black);}
.new_btn a{    color: #fff !important;text-decoration: none;}
.dlt-btn {border: none;background: #000;color: #fff;padding: 3px 8px;border-radius: 3px;font-size: 14px; text-decoration: none;}
.dlt-btn:hover{  background: linear-gradient(to right, red, black);}
.footer-copy-right{background: linear-gradient(to right, red, black);color: #fff;}
.footer-copy-right .text-muted{color: #fff !important;}
.navbar.navbar-dark {background-image: linear-gradient(to right, red, black, white);}
.highlight_event{display: flex;}
.highlight_inner{display: flex; margin: 0 0 0 100px;}
.modal-footer {margin: 16px 0 0 0;}
.modal-footer button {background: #000 !important;color: #fff !important;}
.modal-footer button:hover{ background-image: linear-gradient(to right, red, black) !important;}
.accordion-item .accordion-button:hover{background-image: linear-gradient(to right, red, black);color: #fff;}
.accordion-button:focus {border: 1px solid red !important; box-shadow: none !important;}
.accordion-button:not(.collapsed) {color: #fff !important;background-color: black !important;}
.nav-link {color: #000 !important;width: 100%;}
.nav-link:hover {color: red!important;}
.accordion-button:not(.collapsed)::after {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 532.9 286.7'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M266.34,239.25c2.7-3.38,3.99-5.36,5.62-7C346.39,157.72,420.86,83.25,495.26,8.7c6.41-6.42,13.41-10.31,22.75-7.76,13.54,3.7,19.31,19.79,11.1,31.18-1.45,2.01-3.24,3.79-5,5.55-80.25,80.23-160.52,160.44-240.78,240.65-11.09,11.08-22.46,11.17-33.43,.21C169.16,197.86,88.43,117.18,7.7,36.49-3.42,25.37-2.39,10.53,10.11,3.05c7.04-4.22,14.31-4.01,21.23,.33,2.64,1.65,4.94,3.94,7.16,6.16,74.36,74.36,148.69,148.74,222.99,223.15,1.51,1.51,2.58,3.46,4.86,6.56Z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important; 
}
nav.sb-sidenav-menu-nested.nav {background: rgba(228, 224, 224, 0.322);}
.sb-sidenav-footer {background-image: linear-gradient(to right, red, black,) !important;}

.edit-btn {padding: 5px 10px;}

.top_head{background-color: #000;text-align: center;padding: 20px 0;color: #fff;}
.sub_btn button{padding: 6px 20px;background-color: #000; color: #fff;border: none;border-radius: 4px;}
.sub_btn button:hover{background: linear-gradient(to right, red, black);}
.sb-sidenav-menu-nested ul {padding: 0 0 0 30px;margin: 0;}
.sb-sidenav-menu-nested ul li {list-style-type: none;}
.sb-sidenav-menu-nested ul li a{text-decoration: none !important;color: darkgray;}
.sb-sidenav-menu-nested ul li a:hover{color: red;}
.sb-sidenav-menu-nested .sub_head{font-size: 14px;}
.sb-sidenav-menu-nested .sub_head:hover{color: #000 !important;}
.data-table-extensions-action {display: none;} */


/* .sb-topnav{background-color:#ffffff!important;box-shadow:0 0 15px 0 rgb(0 0 0 / 30%);outline:0!important;border:0!important;margin:0 0 20px;padding:10px;height:auto;position:fixed;width:100%;top:0;left:0}
#layoutSidenav{margin:110px 0 0;align-items:flex-start}
.side-bar-btn{background-color:rgb(238 49 36 / 15%);height:40px;width:40px;display:flex;align-items:center;justify-content:center;border-radius:5px;color:#EE3124;border:1px solid rgb(238 49 36 / 15%);cursor:pointer;position:relative;top:12px}
.navbar-dark .navbar-nav .nav-link i,.navbar-dark .navbar-nav .dropdown-toggle::after{color:#EE3124}
.navbar-expand .navbar-nav .dropdown-menu{padding:0}
.sb-topnav .dropdown-item{padding:9px 12px}
.sb-topnav .dropdown-item:focus,.sb-topnav .dropdown-item:active{background-color:#EE3124}
#layoutSidenav_nav{background:#fff;height:auto;border:1px solid rgb(238 49 36 / 15%)}
#layoutSidenav .accordion-button,#layoutSidenav .accordion-item{background-color:transparent;border:0;outline:0;box-shadow:0 0;color:#5d5d5d}
.sb-sidenav-footer{display:flex;justify-content:space-between;align-items:center;padding:8px 0 0;margin:10px 0 0;color:#ee3124;font-size:12px;text-transform:uppercase;letter-spacing:.5px}
.accordion-button::after{content:"\f0d7";font-family:'Font Awesome 5 Free';font-weight:900;background-image:none!important;font-size:11px;width:12px;height:12px;background-size:12px;background-color:rgb(238 49 36 / 15%);padding:13px;border-radius:5px;display:flex;align-items:center;justify-content:center;background-position:center}
#layoutSidenav #layoutSidenav_nav{margin:0 20px 30px 0;border-top-right-radius:20px;border-bottom-right-radius:20px;position:sticky;top:110px;padding:8px 20px 20px;flex-basis:100%;box-shadow:0 3px 15px 0 rgb(0 0 0 / 15%);width:100%;max-width:250px;transition:all .3s ease-in-out}
.accordion-body{padding:0}
.accordion-body .nav-link{color:#5d5d5d;text-decoration:none;padding:0 0 8px}
.nav-link.sub_head{font-weight:500;padding:7px 0 14px;position:relative;display:block;width:100%;color:#EE3124;text-transform:uppercase;font-size:12px;letter-spacing:.5px}
.accordion-body ul{list-style-type:none;padding:0;width:100%}
.accordion-body a{text-decoration:none;color:#5d5d5d;display:block;width:100%}
#layoutSidenav .accordion-item{border-bottom:1px solid rgb(0 0 0 / 5%)}
.nav-link.sub_head:after{content:"";border-bottom:1px solid rgb(0 0 0 / 5%);display:block;margin:7px 0 0}
#layoutSidenav .accordion-button{padding:12px 0}
.right-nav{background-color:#fff;border-top-left-radius:20px;border-bottom-left-radius:20px;border:1px solid rgb(238 49 36 / 15%);border-right:0}
.right-nav .modal-content{background-color:transparent;border:0}
.form-group label,.form-group label b, .ui.form .field>label{text-transform:uppercase;font-size:12px;letter-spacing:.5px;font-weight:500;display:block;width:100%;margin:0 0 4px;color:#2d2d2d}
.form-group{margin:0 0 15px}
.right-nav .modal-title{font-weight:600;color:#5d5d5d}
.footer-copy-right div{padding:0!important;text-transform:uppercase;font-weight:500;color:#888!important}
.footer-copy-right{padding:15px 0 30px!important}
.modal-footer .ui.button,.modal-footer button, .ui.button.sap-btn-dark, .ui.button.sap-btn-light{background-color:#EE3124;border-radius:50px;color:#fff !important;text-transform:uppercase;font-size:14px;letter-spacing:.5px;font-weight:600;min-width:150px;padding:8px 12px;min-height:45px;transition:all .3s ease-in-out; box-shadow: 0 0 !important; outline: 0 !important; border: 0 !important;}
.modal-footer .ui.button:hover,.modal-footer button:hover, .ui.button.sap-btn-dark:hover{background-color:#ce2a1f}
.ui.button.sap-btn-light{background-color: #dfdfdf !important; color: #5d5d5d !important;}
.modal-footer{padding:20px 0 10px;display:flex;margin:10px 0 0;border-color:rgb(238 49 36 / 15%)}
.form-control,.dataTable-input{min-height:50px !important;border-radius:10px!important;border:1px solid rgb(0 0 0 / 15%)!important}
textarea.form-control{min-height:110px}
.modal-header{border-bottom:1px solid rgb(238 49 36 / 15%);padding:20px}
hr{color:#EE3124}
.form-group label b{font-weight:700;color:#ee3124}
body{background-color:#f2f2f2}
.right-nav .modal-content .modal-body{padding:20px}
#layoutSidenav .accordion-button i{color:#5d5d5d;margin:0 6px 0 0;font-size:14px;width:14px;height:14px;display:flex;align-items:center;justify-content:center}
.left-main-heading{background:linear-gradient(90deg,#EE3124,#2D2926);box-shadow:0 3px 7px 0 rgb(0 0 0 / 15%);color:#fff;display:flex;align-items:center;justify-content:center;font-size:16px;font-weight:500;padding:10px 0;margin:10px 0;border-radius:50px}
.left-main-heading i{margin:0 6px 0 0}
#layoutSidenav.toggle-full-width #layoutSidenav_nav{transform:translateX(-100%);max-width:0;opacity:0;visibility:hidden;margin:0}
#layoutSidenav.toggle-full-width #layoutSidenav_content{max-width:calc(100% - 0px)}
#layoutSidenav #layoutSidenav_content{width:100%;flex-basis:100%;max-width:calc(100% - 270px);margin-left:auto;margin-right:0} */


/* Login Page Code */
.image-container img {
    transition: transform 0.3s ease-in-out;
}

.image-container img:hover {
    transform: scale(1.3);
}
.login-page .card-header{display:flex;align-items:center;justify-content:center;background-color:transparent;padding:0}
.login-page .card{border-radius:20px;overflow:hidden;box-shadow:0 0!important;background-color:transparent}
.login-page .card-body{background-color:transparent}
.login-page .card-header .font-weight-light{text-transform:capitalize;color:#fff!important;background-color:#EE3124;padding:2px 15px 5px;border-radius:30px;min-height:30px;display:flex;align-items:center;justify-content:center;line-height:normal;margin:10px 0 0}
.login-page{background:#4a100c}
.login-page .card-body .card-body.text-black{padding:0}
.login-page .card-body .ui.form .field>label{color:#fff}
.login-page .card-header .login-head-logo {display: flex;width: calc(100% - 30px);justify-content: center;margin: 0 0 10px;}
.login-page .card-header .login-head-logo img {height: 34px;width: auto;}
.login-page .card-header {border-bottom: 0;}

.login-page main{height:100vh}
.login-page main .container{height:100%}
.login-page main .justify-content-center{align-items:center!important;justify-content:center!important;height:100%}
.login-page main .justify-content-center .ui.button{border-radius:10px!important}

.sap-btn-dark{background-color:#EE3124!important;border-radius:50px!important;min-height:50px!important}
.validation-error {color: red;text-transform: uppercase;font-size: 10px;letter-spacing: .3px;font-weight: 500;position: relative;left: 3px;}

svg.eyes {
    cursor: pointer;
}

/* Login Page Code */
/* 20 may  */
@media (max-width: 767px) {
	nav.sb-topnav.navbar {
		display: block;
	}
	nav.sb-topnav.navbar .d-flex.align-items-start {
		justify-content: space-between;
	}
	nav.sb-topnav.navbar ul.navbar-nav {
		margin: 0 !important;
	}
	nav.sb-topnav.navbar .d-flex.align-items-center {
		justify-content: space-between;
	}
}

/* 20 may  */












@media(min-width:1200px)
{
.sb-topnav.navbar-dark #sidebarToggle{display: none;}
}

.filter_active {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
}
.filter_active p{margin: 0;}
 